import {Link, useLocation, useNavigate} from "react-router-dom";
import AppRoutes from "@fitneks-routes";
import { useAuth } from "@fitneks-commons/hooks";
import React, { useContext, useEffect } from "react";
import css from "./Navbar.module.scss";
import { Button, Col, Container, Icon, IconName, Row, Typography } from "@fitneks-component-library";
import classNames from "@fitneks-commons/classnames";
import "@fitneks-commons/styles/display.scss";
import "@fitneks-commons/styles/spacing.scss";
import "@fitneks-commons/styles/utilities.scss";
import "@fitneks-commons/styles/flex.scss";
import { ThemeContext } from "@fitneks-providers";
import OffCanvas from "../OffCanvas/OffCanvas";
import { isLearner, isTrainer } from "@fitneks-commons/helpers/functions/UserRoles";
import ProfileNavigation from "../ProfileNavigation/ProfileNavigation";
import PublicNavigation from "../PublicNavigation/PublicNavigation";
import { GoLiveBtn, NotificationIcon, SearchForStreams } from "@fitneks-commons/ui";
import { getDeviceType } from "@fitneks-commons/getDeviceType";
import UserAvatarAndName from "@fitneks-pages/Dashboard/organisms/UserAvatarAndName/UserAvatarAndName";
import {useGetWarningAccountQuery} from "./navbar.generated";

export default function Navbar() {
    const { isAuthorized, role, userID } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    // get offCanvas state from provider
    const { updateOffCanvas, updateOffCanvasDirection, offCanvas, offCanvasDirection } = useContext(ThemeContext);

    const handelOffCanvas: React.MouseEventHandler<HTMLSpanElement> = (e) => {
        e.stopPropagation();
        if (offCanvas === "off") {
            updateOffCanvas("on");
        } else {
            updateOffCanvas("off");
        }
    };
    const { data } = useGetWarningAccountQuery({
        variables: {
            id: userID ?? "",
    },
        skip: !isAuthorized,
    });

    const liveStreamPage = location.pathname.includes("/streaming") || location.pathname.includes("/trainer/");
    const isDesktop = getDeviceType("desktop");

    useEffect(() => {
        if (!isAuthorized || isDesktop) {
            updateOffCanvasDirection("right");
        } else {
            updateOffCanvasDirection("left");
        }
    }, [isDesktop, isAuthorized]);



    return (
        <>
             {isAuthorized && data?.user?.warning && ( <Container className={classNames(css["top-bar-warning"], "px-3 py-5")}>
                <Typography align="center" uppercase color={"#fff"} className={"mb-0"}>
                    Please update your account password. &nbsp;
                    <Link to={AppRoutes.dashboard.path + "/settings"} className={"t-white t-underline"}>
                        Click Here to update.
                    </Link>
                </Typography>
            </Container>)}
            {!isAuthorized && !location.pathname.includes(AppRoutes.verification.path) && (
                <Container className={classNames(css["top-bar"], "px-3 py-5")}>
                    <Typography align="center" uppercase color={"#fff"} className={"mb-0"}>
                        JOIN OUR LIVE WORKOUT PARTY NOW. &nbsp;
                        <Link to={AppRoutes.register.route()} className={"t-white t-underline"}>
                            SIGN UP
                        </Link>
                        &nbsp;or&nbsp;
                        <Link to={AppRoutes.login.route()} className={"t-white t-underline"}>
                            LOG IN
                        </Link>
                    </Typography>
                </Container>
            )}
            <Container tag={"header"} bg={"#353C3A"} color={"#fff"} className={classNames(css["header"], "px-4 py-3")}>
                <Row gapX={0} gapY={0} className={"d-flex align-items-center"}>
                    <Col xs={2} className={"d-block d-lg-none"}>
                        {isAuthorized && (
                            <span onClick={handelOffCanvas} className={"cursor-pointer"} aria-label={"Nav bar toggle"}>
                                <UserAvatarAndName onlyAvatar={true} orientation={"top"} avatarSize={38} isSharing={false} />
                            </span>
                        )}
                    </Col>
                    <Col
                        xs={8}
                        lg={4}
                        className={classNames(css["header-col"], "d-flex align-items-center justify-content-start")}
                    >
                        <Link className={css["site-logo"]} to={AppRoutes.index.route()} reloadDocument={liveStreamPage}>
                            <Icon name={IconName.FitneksLogo} width={305} height={isDesktop ? 64 : 38} color={"#fff"} />
                            <span className={"sr-only"}>Home</span>
                        </Link>
                    </Col>
                    <Col
                        lg={8}
                        className={classNames(
                            css["header-col"],
                            "d-none d-lg-flex align-items-center justify-content-end"
                        )}
                    >
                        {(!isAuthorized || isLearner(role)) && <SearchForStreams />}
                        {isTrainer(role) && <GoLiveBtn position={"bottom"} />}
                        {!isTrainer(role) && (
                            <Button
                                className={classNames(css["learner-btn"])}
                                title={"LIVE CHALLENGES"}
                                size={"small"}
                                style={"outline-rounded"}
                                onClick={() => {
                                    liveStreamPage
                                        ? (window.location.href = `${
                                              import.meta.env.FITNEKS_APP_URL
                                          }${AppRoutes.liveChallenges.route()}`)
                                        : navigate(AppRoutes.liveChallenges.route());
                                }}
                            />
                        )}
                        {isAuthorized && <NotificationIcon />}
                        <Link
                            className={css[""]}
                            to={isAuthorized ? AppRoutes.dashboard.children.myProfile.route() : AppRoutes.login.route()}
                            reloadDocument={liveStreamPage}
                        >
                            <Icon name={IconName.User2} width={30} height={32} color={"#fff"} />
                            <span className={"sr-only"}>Dashboard</span>
                        </Link>
                        <span onClick={handelOffCanvas} className={"cursor-pointer"} aria-label={"Nav bar toggle"}>
                            <Icon
                                name={offCanvas === "off" ? IconName.Bars : IconName.Cross}
                                width={35}
                                height={35}
                                color={"#fff"}
                            />
                        </span>
                    </Col>
                    <Col xs={2} className={"d-flex d-lg-none align-items-center justify-content-end"}>
                        {!isAuthorized && (
                            <span onClick={handelOffCanvas} className={"cursor-pointer"} aria-label={"Nav bar toggle"}>
                                <Icon
                                    name={offCanvas === "off" ? IconName.Bars : IconName.Cross}
                                    width={35}
                                    height={35}
                                    color={"#fff"}
                                />
                            </span>
                        )}
                    </Col>
                </Row>
            </Container>

            <OffCanvas width={300} direction={offCanvasDirection} show={offCanvas}>
                {isAuthorized && <ProfileNavigation showAll={!isDesktop} />}
                {!isAuthorized && <PublicNavigation />}
            </OffCanvas>
        </>
    );
}
